<template>
  <div class="sign-up-wrap-email purple-bg pt-8 pb-4">
    <div class="small-container">
      <div class="sign-up-wrap-email--logo">
        <a>
          <img src="@/assets/images/general/wear-it-logo.svg"/>
        </a>
      </div>

      <div class="sign-up-wrap--email">
        <div class="alert alert-danger" role="alert" v-if="errorLogin.show" v-text="errorLogin.message"></div>
        <h2 class="h2-style text-center">{{ __t('login.title') }}</h2>
        <a :href="fbLink" class="btn-default btn-classic" target="_blank">{{ __t('login.with-facebook') }}</a>
        <div class="form-group text-center mb-2">
          OR
        </div>
        <form @submit.prevent="handleLogin">
          <div class="form-group">
            <!-- <label for="username">{{ __t('login.username') }}</label> -->
            <input
              :placeholder="__t('login.username')"
              type="text"
              class="form-control"
              @keydown="clearTo"
              @keyup="validateUsername(user.username)"
              :class="{ 'is-invalid': !validate.username }"
              required
              v-model="user.username" id="username" aria-describedby="username"/>
            <small class="form-text invalid-feedback"
                   v-text="(user.username && user.username.length > 36) ? 'Max 36 characters' : 'Username cannot be empty.'"></small>
          </div>
          <div class="form-group">
            <!-- <label for="exampleInputPassword1">{{ __t('login.password')}}</label> -->
            <input
              :placeholder="__t('login.password')"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': !validate.password }"
              @keydown="clearTo"
              @keyup="validatePassword(user.password)"
              required
              v-model="user.password"
              id="exampleInputPassword1"/>
            <small class="form-text invalid-feedback">{{ __t('login.password-not-valid') }}</small>
            <small>
              <router-link :to="{ name: 'ForgotPassword' }">{{ __t('login.forgot-password') }}</router-link>
            </small>
          </div>
          <div class="yellow-button">
            <button type="submit" class="btn-default btn-shadow" :disabled="!valid">{{ __t('login.submit') }}</button>
          </div>
          <!-- <small>
            {{ __t('sign-up.terms-description') }} <br/>
            <router-link class="text-underline" :to="{ name: 'Page', params: { slug: 'terms' }}" v-text="__t('sign-up.terms-privacy')"></router-link>.
          </small> -->
        </form>
      </div>
      <!-- <h5 class="h5-style-lg" v-html="generateRegisterLinkFromTranslations()"></h5> -->
      <h5 class="h5-style-lg">
        {{ __t('login.switch-to-signup-1') }}&nbsp;
        <router-link :to="{ name: 'SignUpEmail' }" class="text-underline">here</router-link>&nbsp;
        {{ __t('login.switch-to-signup-2') }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: 'Let our inclusive community help you decide what to wear! Express your personal style and share your looks on the app'
      }
      // Add other meta tags as needed
    ]
  },
  data () {
    return {
      loading: false,
      valid: false,
      logedUser: false,
      errorLogin: {
        show: false,
        message: 'Invalid Credentials'
      },
      user: {
        username: '',
        password: ''
      },
      validate: {
        username: true,
        password: true
      },
      settimeout: null,
      fbLink: '#'
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'searchBase', 'fbAuthUlr']),
    handleLogin () {
      if (!this.loading) {
        this.loading = true
        this.login(this.user).then((resp) => {
          this.errorLogin = false
          resp.user.setup_step < 4 && this.searchBase()
          if (resp) {
            switch (resp.user.setup_step) {
              case 1:
                this.$router.push({ name: 'UpdateAvatar' })
                break
              case 2:
                this.$router.push({ name: 'UpdateProfile' })
                break
              case 3:
                this.$router.push({ name: 'StyleQuiz' })
                break
              default:
                this.$router.push({ name: 'Home' })
                break
            }
          }
        }).catch((err) => {
          this.errorLogin.show = true
          this.errorLogin.message = err.response.data.result
          this.loading = false
        })
      }
    },
    validatePassword () {
      if (this.user.password?.length > 2) {
        this.settimeout = setTimeout(() => {
          let data = this.user.password.trim(' ')
          this.validate.password = (data && data !== '' && data.length > 7)
          this.validateForm()
        }, 2000)
      }
    },
    validateUsername () {
      this.settimeout = setTimeout(() => {
        let data = this.user.username.trim(' ')
        this.validate.username = data && data !== '' && data.length < 37
        this.validateForm()
      }, 2000)
    },
    clearTo () {
      clearTimeout(this.settimeout)
    },
    validateForm () {
      this.valid = this.validate.username && this.validate.password
    }
  },
  created () {
    this.fbAuthUlr().then(resp => {
      this.fbLink = resp.facebook
    })
  },
  mounted () {
    this.user.username = this.$route.query.username
  }
}
</script>
